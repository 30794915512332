<template>
  <div class="desktopLayoutClass"  style="display: flex; flex-direction: column; margin: 20px;">
    <v-bottom-navigation v-if="!isLoggedIn && !isApp" style="position: fixed; padding:10px 20px!important; padding:5px; width:100vw; height:70px !important" color="primary">
      <!-- <v-btn value="recent"  class="bottomNavBarBtn" >
        <a href="/customer/home" style="text-decoration: none">
          <img v-if="pageName!='HomePage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_color.svg" style="height: 2em"
          />
          <p v-if="pageName!='HomePage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
        </a>
      </v-btn> -->

      <v-btn value="recent"  class="bottomNavBarBtn" >
        <a href="/customer/home" style="text-decoration: none">
          <img v-if="pageName!='CustomerHomeScreen'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_color.svg" style="height: 2em"
          />
          <p v-if="pageName!='CustomerHomeScreen'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
        </a>
      </v-btn>

      <v-btn value="bookings" v-if="isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/mybookings" style="text-decoration: none">
          <img v-if="pageName!='BookingPage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_color.svg" style="height: 2em"
          />

          <br>
          <p v-if="pageName!='BookingPage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
        </a>
      </v-btn>
      <v-btn value="bookings" v-if="!isLoggedIn"  class="bottomNavBarBtn">
         <a href="/login" style="text-decoration: none">
          <img v-if="pageName!='BookingPage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_color.svg" style="height: 2em"
          />

          <br>
          <p v-if="pageName!='BookingPage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
        </a>
      </v-btn>

      <v-btn value="nearby" v-if="isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/Packages/" style="text-decoration: none">
          <img
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/mymed_default.svg" style="height: 2em"
          />
          
          <p style="color: Grey; font-weight: 300;">{{ $t("Customer.Home.Packages") }}</p>
        </a>
      </v-btn>
      <v-btn value="nearby" v-if="!isLoggedIn"  class="bottomNavBarBtn">
        <a href="/login" style="text-decoration: none">
          <img
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/mymed_default.svg" style="height: 2em"
          />
          <p style="color: Grey; font-weight: 300;">{{ $t("Customer.Home.Packages") }}</p>
        </a>
      </v-btn>

      <v-btn value="nearby" v-if ="isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/profile" style="text-decoration: none">
          <img v-if="pageName!='UserProfile'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_color.svg" style="height: 2em"
          />
          <br>
          <p v-if="pageName!='UserProfile'" style="color: #828282; font-weight:300;">{{ $t("Customer.Home.Profile") }}</p>
          <p v-else style="font-weight: 300;">{{ $t("Customer.Home.Profile") }}</p>
        </a>
      </v-btn>
      <v-btn value="nearby" v-if="!isLoggedIn"  class="bottomNavBarBtn">
        <a href="/login" style="text-decoration: none">
          <img v-if="pageName!='UserProfile'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_color.svg" style="height: 2em"
          />
          <br>
          <p v-if="pageName!='UserProfile'" style="color: #828282; font-weight:300;">{{ $t("Customer.Home.Profile") }}</p>
          <p v-else style="font-weight: 300;">{{ $t("Customer.Home.Profile") }}</p>
        </a>
      </v-btn>
    </v-bottom-navigation>

    <v-bottom-navigation style="position: fixed; padding:5px; width:100vw;padding-bottom:10px !important; height:70px !important" color="primary" v-else-if=" isLoggedIn && kbzEmployee">
      <!-- <v-btn value="recent" class="bottomNavBarBtn">
        <a href="/customer/home" style="text-decoration: none">
          <img v-if="pageName!='HomePage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_color.svg" style="height: 2em"
          />
          <p v-if="pageName!='HomePage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
        </a>
      </v-btn> -->

      <v-btn value="recent" class="bottomNavBarBtn">
        <a href="/customer/home" style="text-decoration: none">
          <img v-if="pageName!='CustomerHomeScreen'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_color.svg" style="height: 2em"
          />
          <p v-if="pageName!='CustomerHomeScreen'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
        </a>
      </v-btn>

      <v-btn value="bookings" v-if="isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/mybookings" style="text-decoration: none">
          <img v-if="pageName!='BookingPage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_color.svg" style="height: 2em"
          />

          <br>
          <p v-if="pageName!='BookingPage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
        </a>
      </v-btn>
      <v-btn value="bookings" v-if="!isLoggedIn"  class="bottomNavBarBtn">
         <a href="/login" style="text-decoration: none">
          <img v-if="pageName!='BookingPage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_color.svg" style="height: 2em"
          />

          <br>
          <p v-if="pageName!='BookingPage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
        </a>
      </v-btn>

      <v-btn value="KBZCare" @click="redirectKbzInfo()"  class="bottomNavBarBtn">
        <!-- @click="redirectKbzInfo()" -->
        <div class="kbzcare rounded-circle text-center" style=" position:relative; bottom:30px; box-shadow: 0px 10px 28px 0px #1366BE4D;">
          <br />
          <p class="text-circle" style="color: white; font-weight: 400; vertical-align:middle;"  >
            KBZ <br />
            Care
          </p>
        </div>
      </v-btn>

      <v-btn value="nearby" v-if="isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/Packages/" target="_blank" style="text-decoration: none">
          <img
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/mymed_default.svg" style="height: 2em"
          />
          <p style="color: Grey; font-weight: 300;">{{ $t("Customer.Home.Packages") }}</p>
        </a>
      </v-btn>
      <v-btn value="nearby" v-if="!isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/Packages/" target="_blank" style="text-decoration: none">
          <img
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/mymed_default.svg" style="height: 2em"
          />
          <p style="color: Grey; font-weight: 300;">{{ $t("Customer.Home.Packages") }}</p>
        </a>
      </v-btn>

      <v-btn value="nearby" v-if ="isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/profile" style="text-decoration: none">
          <img v-if="pageName!='UserProfile'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_color.svg" style="height: 2em"
          />
          <br>
          <p v-if="pageName!='UserProfile'" style="color: #828282; font-weight:300;">{{ $t("Customer.Home.Profile") }}</p>
          <p v-else style="font-weight: 300;">{{ $t("Customer.Home.Profile") }}</p>
        </a>
      </v-btn>
      <v-btn value="nearby" v-if="!isLoggedIn"  class="bottomNavBarBtn">
        <a href="/login" style="text-decoration: none">
          <img v-if="pageName!='UserProfile'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_color.svg" style="height: 2em"
          />
          <br>
          <p v-if="pageName!='UserProfile'" style="color: #828282; font-weight:300;">{{ $t("Customer.Home.Profile") }}</p>
          <p v-else style="font-weight: 300;">{{ $t("Customer.Home.Profile") }}</p>
        </a>
      </v-btn>
    </v-bottom-navigation>

    <v-bottom-navigation style="position: fixed; padding:10px 20px!important; padding:5px; width:100vw;height:70px !important" color="primary" v-else-if="isLoggedIn && !kbzEmployee">
      <!-- <v-btn value="recent"  class="bottomNavBarBtn" >
        <a href="/customer/home" style="text-decoration: none">
          <img v-if="pageName!='HomePage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_color.svg" style="height: 2em"
          />
          <p v-if="pageName!='HomePage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
        </a>
      </v-btn> -->

      <v-btn value="recent"  class="bottomNavBarBtn" >
        <a href="/customer/home" style="text-decoration: none">
          <img v-if="pageName!='CustomerHomeScreen'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/home_color.svg" style="height: 2em"
          />
          <p v-if="pageName!='CustomerHomeScreen'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Home") }}</p>
        </a>
      </v-btn>

      <v-btn value="bookings" v-if="isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/mybookings" style="text-decoration: none">
          <img v-if="pageName!='BookingPage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_color.svg" style="height: 2em"
          />

          <br>
          <p v-if="pageName!='BookingPage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
        </a>
      </v-btn>
      <v-btn value="bookings" v-if="!isLoggedIn"  class="bottomNavBarBtn">
         <a href="/login" style="text-decoration: none">
          <img v-if="pageName!='BookingPage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/schedule_color.svg" style="height: 2em"
          />

          <br>
          <p v-if="pageName!='BookingPage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
          <p v-else style= "font-weight: 300;">{{ $t("Customer.Home.Boookings") }}</p>
        </a>
      </v-btn>

      <v-btn value="nearby" v-if="isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/Packages/" style="text-decoration: none">
          <img  v-if="pageName!='PackagePage'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/mymed_default.svg" style="height: 2em"
          />
          <!-- https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/packagesActiveIcon.svg -->
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/packagesActiveIcon.svg" style="height: 2em"
          />
          <p v-if="pageName!='PackagePage'" style="color: #828282; font-weight: 300;">{{ $t("Customer.Home.Packages") }}</p>
          <p v-else style= " font-weight: 300;">{{ $t("Customer.Home.Packages") }}</p>
        </a>
      </v-btn>
      <v-btn value="nearby" v-if="!isLoggedIn"  class="bottomNavBarBtn">
        <a href="/login" style="text-decoration: none">
          <img
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/mymed_default.svg" style="height: 2em"
          />
          <p style="color: Grey; font-weight: 300;">{{ $t("Customer.Home.Packages") }}</p>
        </a>
      </v-btn>

      <v-btn value="nearby" v-if ="isLoggedIn"  class="bottomNavBarBtn">
        <a href="/customer/profile" style="text-decoration: none">
          <img v-if="pageName!='UserProfile'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_color.svg" style="height: 2em"
          />
          <br>
          <p v-if="pageName!='UserProfile'" style="color: #828282; font-weight:300;">{{ $t("Customer.Home.Profile") }}</p>
          <p v-else style="font-weight: 300;">{{ $t("Customer.Home.Profile") }}</p>
        </a>
      </v-btn>
      <v-btn value="nearby" v-if="!isLoggedIn"  class="bottomNavBarBtn">
        <a href="/login" style="text-decoration: none">
          <img v-if="pageName!='UserProfile'"
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_default.svg" style="height: 2em"
          />
          <img v-else
          src="https://s3iconimages.mymedicine.com.mm/://d3vsnl8idgwrlw.cloudfront.net/profile_color.svg" style="height: 2em"
          />
          <br>
          <p v-if="pageName!='UserProfile'" style="color: #828282; font-weight:300;">{{ $t("Customer.Home.Profile") }}</p>
          <p v-else style="font-weight: 300;">{{ $t("Customer.Home.Profile") }}</p>
        </a>
      </v-btn>
    </v-bottom-navigation>

  </div>
</template>

<script>
export default {
  name: "bottomnavbar",
   props: ['pageName','isLoggedIn', 'kbzEmployee'],
  data() {
    return {
      value: "test",
      isMobile : false,
      isApp: false,

    }
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.isApp = true;
    }
    // this.kbzEmployee = this.$store.state.employee;
    // console.log(this.kbzEmployee);
  },
   methods: {
     redirectKbzInfo(){
           this.$router.push({
        name: "KbzCaresInfo",
      });
     }
   },

};
</script>

<style>
.kbzcare {
  background-image: linear-gradient(131.33deg, #1467bf 15.48%, #0054ac 85.41%);

  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.text-circle {
  align-items: center;

  padding-top: 5pxs;
}
 .bottomNavBarMainDiv{
   max-width: 425px !important;
   margin-left: auto;
   margin-right: auto;
 }
/* @media (min-width: 800px){
  .bottomNavBarMainDiv{
   position: fixed;
   margin-left: auto !important ;
    margin-right: auto !important ;


  }
} */
.bottomNavBarBtn{
  padding-bottom: 5px !important;
}
/* .v-btn__content{
  justify-content: flex-end !important;
} */

.v-item-group.v-bottom-navigation {
  box-shadow: none !important;
  border: 1px solid #E0E0E0;
}
</style>
<style scoped>
 .v-btn__content{
  justify-content: flex-end !important;
}
</style>
